.FlexBox {
  display: flex;
}

.MarginHoriz10px {
  margin-left: 10px;
  margin-right: 10px;
}

.ComponentWrapper {
  display: flex;
}

.SpinWrapper {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Flex1 {
  flex: 1;
}

.HeaderWrapper {
  display: flex;
  margin-left: auto;
}

.HeaderWrapper > * {
  margin-left: 15px;
}

.NodeInfo {
  display: flex;
  height: 80vh;
  flex: 1;
}

.Section {
  border: 1px solid silver;
  margin-top: 10px;
  padding: 10px;
  flex: 1;
  overflow: auto;
}

.TableHeader {
  font-size: x-large;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.LabelColumn {
  font-weight: bold;
}

.OddRow {
  background-color: #f8f8f8;
}

.ModalTitle {
  font-size: x-large;
  font-weight: bold;
}

.UpdateNodeConfig {
  overflow-y: auto;
  max-height: 80vh;
}

.UpdateNodeConfigFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.Details {
  width: 100%;
  display: flex;
  overflow: auto;
  height: 72vh;
}

.font12 {
  font-size: 12px;
}

.ButtonsSection {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}

.ButtonsWrapper {
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-left: 1px solid rgb(245, 245, 245);
  margin-left: 10px;
}
