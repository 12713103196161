.AlarmDetails {
  right: 0px;
  top: 0;
  width: 300px;
  padding: 15px;
  padding-top: 5px;
  background: white;
  position: absolute;
  z-index: 200;
  margin-right: 52px;
}

.EventConfidenceModal {
  body: {height:600}
}