.GridControls {
  position: absolute;
  width: 300px;
  padding: 10px;
  background: white;
  min-height: 438px;
  overflow: auto;
  z-index: 100;
  opacity: 0.8;
  height: 100%;
}

.MapController {
  display: flex;
  position: absolute;
  z-index: 100;
  opacity: 0.95;
}

.MapOptions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.MenuController {
  width: 100%;
}

.ControllerWrapper {
  background: white;
  height: 100%;
  width: 340px;
  padding: 13px;
  overflow: auto;
}
