.LikelyLeakLocationWrapper {
  font-weight: bold;
  display: flex;
  align-items: center;
}

.LeakLocationBox {
  width: 15px;
  height: 15px;
  margin-left: 5px;
  border: 1px solid silver;
}

.LikelyLeakLocation1 {
  background-color: rgb(255, 77, 79);
}

.LikelyLeakLocation2 {
  background-color: rgb(250, 140, 22);
}

.ActualLeakSource {
  background-color: rgb(250, 200, 22);
}

.marginTopBottom5 {
  margin-top: 5px;
  margin-bottom: 5px;
}

.flexAlignedCenter {
  display: flex;
  align-items: center;
}

.selectActualLeakSourceWrapper {
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
  justify-content: space-between;
}

.AlarmDetails {
  font-size: 12px;
}

.leakRate {
  display: flex;
  align-items: center;
}

.uncertainty {
  font-size: 11px;
  margin: 0 5px 0 3px;
}

.noWrap {
  white-space: nowrap;
}
