body {
  overflow: hidden;
}

.custom-overlay {
  position: absolute;
  width: 200px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}