.alarm-details-container {
    border-radius: 5px;
    font-size: 11.5px;
    h1 {
      text-align: center;
      color: #666;
      font-size: 16px;
      font-weight: bold;
    }
    h2 {
      font-size: 14px;
      color: #555;
      font-weight: bold;
      margin-top: 15px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
      margin-top: 10px;
      margin-bottom: 15px;
    }
    td,
    th {
      border: 1px solid #ddd;
      padding: 8px;
      text-align: left;
    }
    tr.highlighted {
      background-color: #87ceeb;
    }
  }
  