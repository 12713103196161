.Title {
  font-size: 20px;
}

.Alarm {
  width: 100%;
  background: #f2f5fb;
  padding: 20px;
  margin-top: 10px;
}

.AlarmTitle {
  font-size: 26px;
}

.Input {
  width: 70px;
  height: 50px;
  text-align: center;
  background: white;
  border: none;
}

.TextAreaWrapper {
  width: 100%;
  font-size: 14px;
  margin-top: 25px;
}

.TextArea {
  width: 100%;
  height: 150px;
  margin-top: 10px;
}

.SaveBtn {
  width: 150px;
  float: right;
}
