.AlarmSection {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  margin-top: 25px;
}

.FlexOne {
  flex: 1;
}

.Element {
  width: 300;
  text-align: right
}