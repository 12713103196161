.VehicleWrapper {
  border-style: solid;
  border-width: 3px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 20px;
  opacity: 85%;
  margin-left: 16px;
  margin-top: 5px;
  margin: 10px;
  padding: 8px;
  background-color: #ffffff;
  cursor: pointer;
}

.Center {
  display: flex;
  align-items: center;
}
