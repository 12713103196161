.GridVisibility {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
}

.MarginBottom {
  margin-bottom: 10px;
}

.ColoredSquare {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.LegendWrapper {
  display: flex;
  align-items: center;
}
