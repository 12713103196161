.AnalyticsWrapper {
  height: 100%;
  overflow: hidden;
}

.SelectionWrapper {
  background-color: white;
  border: 2px solid black;
  margin-right: 40px;
  margin-top: 50px;
  opacity: 0.10;
  overflow: hidden;
  padding: 15px;
  position: absolute;
  transition: opacity 0.3s ease;
  right: 0;
}

.SelectionWrapper:hover {
  opacity: 1;
}

.ButtonWrapper {
  margin-right: 1.5rem;
  position: absolute;
  right: 0;
}

.ChartWrapper {
  display: flex;
  justify-content: center;
  max-height: calc(100vh - 150px);  
}

.FlexBox {
  display: flex;
}
