.Container {
  position: relative;
  height: 60vh;
  width: 100%;
  margin: auto;
}

.DisplayBoxContainer {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.TimeRangePickerContainer {
  display: flex;
  justify-content: center;
}
