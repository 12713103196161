.HeaderWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 6;
}

.SearchDiv {
  width: 17.5rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.OrgsDiv {
  margin-left: 0rem;
}

.OrgsSelect {
  width: 15rem;
}

.SwitchWrapper {
  display: flex;
  margin-left: 1rem;
  justify-content: space-evenly;
  width: 15rem;
}

.SwitchText {
  margin: 0;
}

.StatusTagWrapper {
  display: flex;
}

.StatusTag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 1.5rem;
}

.StatusTagP {
  margin: 0;
  font-size: small;
}

.MessageWrapper {
  margin-left: auto;
  margin-right: 1rem;
}

.Clickable:hover {
  cursor: pointer;
}

.NodeInfoModalTitle {
  font-size: x-large;
  font-weight: bold;
  padding: 3px;
}
