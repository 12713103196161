.Container {
    border: 4px solid #ccc;
    padding: 20px;
    max-width: 400px;
    box-shadow:0 0 10px rgba(0, 0, 0, 0.1) ;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
}

.SevenDay {
    font-size: 16px;
    margin-bottom: 8px;
    color: #CD5C5C;
    font-weight: bold;
}

.NinetyDay {
    font-size: 16px;
    margin-bottom: 8px;
    color: #C71585;
    font-weight: bold;
}