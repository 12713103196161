.FlexBox {
  display: flex;
}

.ComponentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DurationTagsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.DurationTag {
  display: flex;
  justify-content: space-evenly;
  margin: 0 4px;
}

.DurationTag:active {
  transition: transform 0.3s ease;
}

.DurationTag:hover {
  cursor: pointer;
}

.Arrow:hover {
  cursor: pointer;
}
