.AddSiteButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.ConfirmSiteButtonWrapper {
    margin-top: 5px;
    border: 1px solid silver;
    padding: 10px;
}

.AddSiteButton {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.AddSiteButton:hover {
  background-color: #45a049;
}

.AddSiteIcon {
  margin: 5px;
  vertical-align: middle;
}

.AddSiteText {
  vertical-align: middle;
}

.CreateSiteModal {
    width: 100%;
}