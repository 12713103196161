.FormWrapper {
  margin-bottom: 20px;
  margin-top: 20px;
}

.CollapseWrapper {
  background: white;
  padding-top: 10px;
}

.Spin {
  text-align: center;
}

.AlarmsLink {
  color: rgb(22, 119, 255);
}

.AlarmsLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
