.AuthHeader {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  img {
    width: 255px;
    height: 70px;
  }
}

.AuthFooter {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  line-height: 1;
}

.AuthSupport {
  opacity: 50%;
  margin-bottom: 15px;
}

.AppWrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}