.KPIReport {
  height: 100%;
  width: 100%;
}

.ControlsWrapper {
  width: 100%;
  margin-bottom: 30px;
}

.SelectOrganizationWrapper {
  width: 300px;
  margin-right: 30px;
}

.marginBottom15 {
  margin-bottom: 15px;
}

.width100 {
  width: 100%;
}

.paddingBottom {
  padding-bottom: 30px;
}

.width50 {
  width: 50%;
}

.alignCenter {
  align-content: center;
}

.graphsWrapper {
  display: flex;
  height: 500px;
  margin-bottom: 50px;
}

.flex {
  display: flex;
}
