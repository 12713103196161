.flexOne {
  flex: 1;
}

.GPAQSLegend {
  border: 0.5px solid silver;
  width: fit-content;
  padding: 5px;
  display: flex;
  margin: 15px auto;
  justify-content: space-evenly;
}

.GPAQSLegend:hover {
  cursor: pointer;
}

.GPAQSLegend > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
}

.GPAQSLegendItem {
  display: flex;
  align-items: center;
}

.ColoredSquare {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}
