.TableHeader {
  font-size: x-large;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.NodeInstallHistory {
  height: 72vh;
  overflow: auto;
}
