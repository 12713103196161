.PointCoordinates {
  border: silver 1px solid;
  padding: 5px;
  margin: 10px;
}

.MarginTop15 {
  margin-top: 15px;
}

.ActivateGridInstructions {
  height: 80px;
  margin-top: 15px;
  margin-bottom: 15px;
}
