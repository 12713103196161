.ButtonWrapper {
  margin-bottom: 20px;
}

.FormFooter {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 0;
}

.Flex {
  display: flex;
}

.OddRow {
  background-color: #f8f8f8;
}

.QrCodeModal {
  max-height: 99%;
  overflow: auto;
}

.QrCodeControls {
  margin-top: 20px;
  margin-bottom: 20px;
}

.QrCodeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
}

.ReinstallText {
  margin-top: 18px;
}

.ReinstallText > span {
  font-size: 12px;
  color: silver;
}

.NodeInfoModalTitle {
  font-size: x-large;
  font-weight: bold;
  padding: 3px;
}