.Wrapper {
  height: 80%;
  margin: 5px;
  width: 98%;
}

.PlayButtonWrapper {
  margin-left: 8px;
  margin-right: 8px;
}

.Selection {
  align-items: center;
}