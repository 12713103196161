.TableWrapper {
  height: auto;
}
.AlarmsTable {
  overflow: auto;
  border-collapse: collapse;
  width: 100%;
  td,
  th {
    border: 0.5px solid #f0f0f0;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
  }

  tr:nth-child(even) {
    background-color: #f0f0f0;
  }

  td:hover {
    cursor: pointer;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.blinkNotification {
  background-color: red;
  animation: blink 2s infinite;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  border-radius: 50%;
  margin-left: 5px;
  font-weight: bold;
  font-size: 10px;
}

.Flex {
  display: flex;
}
