.DataTypeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.FlexBox {
  display: flex;
}

.vertical-dropdown .ant-select-dropdown {
  display: block;
}

.OnOffSelectionWrapper {
  display: flex;
  justify-content: space-evenly;
}

.TimezoneSelectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.NoChartData {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}