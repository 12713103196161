.form {
  background: #f0f2f5;
  padding: 10px;
  margin-top: 15px;
}

.body {
  display: flex;
}

.oddRow {
  background-color: #f8f8f8;
}

.activeRow {
  background-color: #4096ff;
  color: white;
}

.section {
  width: 50%;
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.selection {
  display: flex;
  flex-direction: column;
}

.timeRangeSelection {
  display: flex;
  margin: 32px auto 0;
}

.rangePicker {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.submitButton {
  margin: 12px auto;
}

.historyHeader {
  display: flex;
}

.refreshButton {
  border-radius: 50%;
  margin-left: 15px;
}

.marginRight12px {
  margin-right: 12px;
}

.marginTop4px {
  margin-top: 4px;
}
