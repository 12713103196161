.Layout {
  height: 100vh;
  width: 100%;
  background: #dadada;
  overflow: hidden;
}

.Toolbar {
  background: white;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-shadow: 0px 0px 10px silver;
}

.Body {
  height: 100%;
  width: 100%;
  display: flex;
}

.ChildrenWrapper {
  height: calc(100% - 60px);
  width: 100%;
}

.LogoWrapper {
  width: 150px;
  height: 37px;
}

.IconsWrapper {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.MenuElement {
  text-align: center;
  font-size: 10px;
  margin: 0 10px;
  align-self: flex-end;
  :hover {
    cursor: pointer;
  }
}

.FontSize19 {
  font-size: 19px;
}

.OrgSiteWrapper {
  font-size: 16;
  font-weight: 500;
  opacity: 0.8;
  display: flex;
  margin-top: 5px;
  margin-left: 5px;
}

.LogoOrgSiteWrapper {
  display: flex;
  width: 25%;
}

.UserInfoWrapper {
  display: flex;
  margin-left: auto;
}