.RefreshButton {
  margin-left: 15px;
  margin-top: 20px;
}

.DateSelectionWrapper {
  display: flex;
  align-items: center;
}

.TimezoneSelectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UnitSelectionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TimezoneSwitch {
  margin-top: 15px;
}
.EpaCheckboxLabel {
  margin-left: 65px
}
.EpaCheckboxContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 10px;
  background-color: #f9f9f9;
  margin-left: 65px;
  margin-top: 15px;
}
