.ListItem {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
}

.ListItem > * {
    margin-right: 25px;
}

.PassIcon {
    color: green;
    font-size: 24px;
}

.FailIcon {
    color: red;
    font-size: 24px;
}
.Footer {
    text-align: right;
}
