.SideNavigation {
  position: absolute;
  right: 0;
  z-index: 300;
  opacity: 0.95;
  display: flex;
}

.SideDrawerWrapper {
  background: #dadada;
  height: 100%;
  width: 300px;
}

.SearchBarWrapper {
  margin-top: 10px;
  height: 60px;
  width: 100%;
  padding-top: 7px;
  padding-left: 5px;
  padding-right: 5px;
}

.SearchBar {
  background: white;
  border: solid 1px silver;
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
}

.SearchInput {
  border: none;
  flex: 1px;
  outline: none;
}

.SideBarCard {
  width: 100%;
  height: 80px;
  background: white;
  border: 0.5px solid silver;
  align-items: center;
  position: relative;
  display: flex;
  :hover {
    cursor: pointer;
  }
}

.Flex {
  display: flex;
  align-items: center;
}

.CardTitle {
  font-weight: 500;
  font-size: 20px;
  opacity: 85%;
  margin-left: 16px;
  margin-top: 5px;
}

.CardTextWrapper {
  display: flex;
}

.RadioGroupWrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.ActiveStatusContainer {
  height: 14px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
}

.StatusTagContainer {
  position: absolute;
  right: 0;
}

.ActiveText {
  color: green;
  font-size: 12px;
}

.InactiveText {
  color: red;
  font-size: 12px
}

.SearchIcon {
  font-size: 20px;
  color: #1677ff; /* Set icon color */
}

.AddSiteButtonWrapper {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.AddSiteButton {
  background-color: #4CAF50; 
  border: none; 
  color: white; 
  padding: 10px 10px; 
  text-align: center; 
  display: flex;
  align-items: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer; 
  transition: background-color 0.3s;
}

.AddSiteButton:hover {
  background-color: #45a049;
}

.AddSiteIcon {
  margin: 5px;
  vertical-align: middle;
}

.AddSiteText {
  vertical-align: middle; 
}